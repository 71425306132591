
import {
  IonPage,
  toastController,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonContent,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../../components/Empty.vue";
import { useStore } from "vuex";
import { Analytics } from "../../../../common/analytics";
/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: {
    IonPage,
    Empty,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonContent,
    IonImg,
  },
  props: {
    // postId
    id: {
      type: [Number, String],
      default: () => null,
    },
    level: {
      type: [Number, String],
      default: () => 1,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const screenName = `PAGE-MISSION_${props.id}_STAMP`;
    const analytics = new Analytics();
    const state = reactive({
      stampFillters: [
        {
          filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #d4ff6e)",
        },
        {
          filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #7ae4ff)",
        },
        {
          filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffdf61)",
        },
        {
          filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ebb5ff)",
        },
        {
          filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffc48d)",
        },
      ],
      randomNum: Math.floor(Math.random() * 5),
      mission: {
        loading: true,
        data: {
          name: store.getters["getPage"].title,
          id: 0,
        },
      },
      stamp: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    const openToast = async (message: string) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const fetch = async (event: any = null, loadMore = false) => {
      if (state.mission.data.id == 0) {
        await services.missionFindones(Number(props.id)).then(
          (response) => {
            const { data } = response;

            state.mission.data = data;
            state.mission.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            openToast(message);
          }
        );
      }

      const params = {
        page: state.stamp.page,
        size: state.stamp.size,
        level: props.level,
      };

      await services.stampMissionFindone(Number(props.id), params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.stamp.items = [];
            }
          }
          data.items.some((item: object) => {
            state.stamp.items.push(item);
          });

          state.stamp.totalItems = data.totalItems;
          state.stamp.totalPages = data.totalPages;
          state.stamp.loading = false;
          state.stamp.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.stamp.items = [];
            }
          }
          state.stamp.loading = false;
          state.isScrollDisabled = true;
        }
      );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onStampLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "StampId",
        params: { id: id },
      });
    };

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.loading = true;
      state.stamp.page = 1;
      state.stamp.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // onMounted
    onMounted(() => {
      state.stamp.items = [];
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { state, onStampLink, onClose, onRefresh, onLoadData };
  },
});
